<script setup>
import { ref } from 'vue';
import { Head, Link, usePage } from '@inertiajs/vue3';
import { URLEncoder } from '../../utils/URLEncoder';
import LanguageList from '@/Components/Footer/LanguageList.vue';

import { loadLanguageAsync, getActiveLanguage, trans } from 'laravel-vue-i18n';
const currentLanguage = ref(getActiveLanguage());

const page = usePage();
const user = ref(page.props.auth.user);

const locales = ref(page.props.available_locales);

</script>

<template>
    <div class="grid grid-cols-1 md:grid-cols-3 m-auto gap-6 md:gap-6">
        <div>
            <h5 class="text-base font-semibold mb-4 uppercase">{{ $t('Private language lessons') }}</h5>
            <ul class="mt-3">
                <li class="my-2"><a :href="`/${currentLanguage}/${$t('routes.tutoring')}/${URLEncoder.encode($t('subjects.English').toLowerCase())}?speaking=${currentLanguage}`">{{ $t('Private English language lessons') }}</a></li>
                <li class="my-2"><a :href="`/${currentLanguage}/${$t('routes.tutoring')}/${URLEncoder.encode($t('subjects.French').toLowerCase())}?speaking=${currentLanguage}`">{{ $t('Private French language lessons') }}</a></li>
                <li class="my-2"><a :href="`/${currentLanguage}/${$t('routes.tutoring')}/${URLEncoder.encode($t('subjects.Serbian').toLowerCase())}/${URLEncoder.encode($t('subjects.For foreigners').toLowerCase())}?speaking=${currentLanguage}`">{{ $t('Serbian language lessons for foreigners') }}</a></li>
                <li class="my-2"><a :href="`/${currentLanguage}/${$t('routes.tutoring')}/${URLEncoder.encode($t('subjects.Russian').toLowerCase())}?speaking=${currentLanguage}`">{{ $t('Private Russian language lessons') }}</a></li>
            </ul>
        </div>
        <div>
            <h5 class="text-base font-semibold mb-4 uppercase">{{ $t('subjects.Mathematics') }}</h5>
            <ul class="mt-3">
                <li class="my-2"><a :href="`/${currentLanguage}/${$t('routes.tutoring')}/${URLEncoder.encode($t('subjects.Mathematics').toLowerCase())}/${URLEncoder.encode($t('subjects.Middle school').toLowerCase())}?speaking=${currentLanguage}`">{{ $t('Private math lessons for elementary school students') }}</a></li>
                <li class="my-2"><a :href="`/${currentLanguage}/${$t('routes.tutoring')}/${URLEncoder.encode($t('subjects.Mathematics').toLowerCase())}/${URLEncoder.encode($t('subjects.High school').toLowerCase())}?speaking=${currentLanguage}`">{{ $t('Private math lessons for high school students') }}</a></li>
                <li class="my-2"><a :href="`/${currentLanguage}/${$t('routes.tutoring')}/${URLEncoder.encode($t('subjects.Mathematics').toLowerCase())}/${URLEncoder.encode($t('subjects.College').toLowerCase())}?speaking=${currentLanguage}`">{{ $t('University-level math tutoring') }}</a></li>
                <li class="my-2"><a :href="`/${currentLanguage}/${$t('routes.tutoring')}/${URLEncoder.encode($t('subjects.Mathematics').toLowerCase())}/${URLEncoder.encode($t('subjects.Preparation for Entrance Exam').toLowerCase())}?speaking=${currentLanguage}`">{{ $t('Entrance exam preparation in mathematics') }}</a></li>
            </ul>
        </div>
        <div>
            <h5 class="text-base font-semibold mb-4 uppercase">{{ $t('Natural Sciences') }}</h5>
            <ul class="mt-3">
                <li class="my-2"><a :href="`/${currentLanguage}/${$t('routes.tutoring')}/${URLEncoder.encode($t('subjects.Chemistry').toLowerCase())}?speaking=${currentLanguage}`">{{ $t('Private chemistry lessons') }}</a></li>
                <li class="my-2"><a :href="`/${currentLanguage}/${$t('routes.tutoring')}/${URLEncoder.encode($t('subjects.Physics').toLowerCase())}?speaking=${currentLanguage}`">{{ $t('Private physics lessons') }}</a></li>
                <li class="my-2"><a :href="`/${currentLanguage}/${$t('routes.tutoring')}/${URLEncoder.encode($t('subjects.Physics').toLowerCase())}/${URLEncoder.encode($t('subjects.Preparation for Small Graduation').toLowerCase())}?speaking=${currentLanguage}`">{{ $t('Private Tutoring for Small Matura Physics') }}</a></li>
            </ul>
        </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 mx-auto mt-4 md:mt-12 gap-6 md:gap-6">
        <div>
            <h5 class="text-base font-semibold mb-4 uppercase">{{ $t('Exam and Test Preparation') }}</h5>
            <ul class="mt-3">
                <li class="my-2"><a :href="`/${currentLanguage}/${$t('routes.tutoring')}/${URLEncoder.encode($t('subjects.English').toLowerCase())}/${URLEncoder.encode($t('TOEFL').toLowerCase())}?speaking=${currentLanguage}`">{{ $t('TOEFL preparation') }}</a></li>
                <li class="my-2"><a :href="`/${currentLanguage}/${$t('routes.tutoring')}/${URLEncoder.encode($t('subjects.English').toLowerCase())}/${URLEncoder.encode($t('IELTS').toLowerCase())}?speaking=${currentLanguage}`">{{ $t('IELTS preparation') }}</a></li>
                <li class="my-2"><a :href="`/${currentLanguage}/${$t('routes.tutoring')}/${URLEncoder.encode($t('subjects.French').toLowerCase())}/${URLEncoder.encode($t('DELF').toLowerCase())}?speaking=${currentLanguage}`">{{ $t('DELF preparation') }}</a></li>
            </ul>
        </div>
        <div>
            <h5 class="text-base font-semibold mb-4 uppercase">{{ $t('Musical Instruments') }}</h5>
            <ul class="mt-3">
                <li class="my-2"><a :href="`/${currentLanguage}/${$t('routes.tutoring')}/${URLEncoder.encode($t('subjects.Piano').toLowerCase())}?speaking=${currentLanguage}`">{{ $t('Private piano lessons') }}</a></li>
                <li class="my-2"><a :href="`/${currentLanguage}/${$t('routes.tutoring')}/${URLEncoder.encode($t('subjects.Music theory').toLowerCase())}?speaking=${currentLanguage}`">{{ $t('Online music lessons') }}</a></li>
            </ul>
        </div>
        <div>
            <h5 class="text-base font-semibold mb-4 uppercase">{{ $t('Programming and IT') }}</h5>
            <ul class="mt-3">
                <li class="my-2"><a :href="`/${currentLanguage}/${$t('routes.tutoring')}/${URLEncoder.encode($t('subjects.Java').toLowerCase())}/${URLEncoder.encode($t('subjects.College').toLowerCase())}?speaking=${currentLanguage}`">{{ $t('Java Programming for University Students') }}</a></li>
                <li class="my-2"><a :href="`/${currentLanguage}/${$t('routes.tutoring')}/${URLEncoder.encode($t('subjects.Matlab').toLowerCase())}?speaking=${currentLanguage}`">{{ $t('Matlab lessons') }}</a></li>
            </ul>
        </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 m-auto mt-4 md:mt-12 gap-6 md:gap-3">
        <LanguageList />
        <div>
            <h5 class="text-base font-semibold mb-4 uppercase">{{ $t('Support') }}</h5>
            <ul class="mt-3">
                <li class="my-2"><a href="mailto:office@clasora.com" class="hover:underline">office@clasora.com</a></li>
                <li class="my-2">
                    <a v-if="user" href="https://feedback.clasora.com/o_auths/1/start?reason=login" target="_blank" class="hover:underline">{{ $t('Leave Feedback') }}</a>
                    <a v-if="!user" href="https://feedback.clasora.com" target="_blank" class="hover:underline">{{ $t('Leave Feedback') }}</a>
                </li>
                <li class="my-2"><a href="https://discord.gg/bGjERVngq9" target="_blank" class="hover:underline">{{ $t('Discord Server') }}</a></li>
            </ul>
        </div>
        <div>
            <h5 class="text-base font-semibold mb-4 uppercase">{{ $t('Useful Links') }}</h5>
            <ul class="mt-3">
                <li class="my-2"><Link class="block mt-1 font-thin underline" :href="route(`faq.${currentLanguage}`)">{{ $t('policy.faq' ) }}</Link></li>
                <li class="my-2"><Link class="block mt-1 font-thin underline" :href="route(`privacy.${currentLanguage}`)">{{ $t('policy.privacy_policy') }}</Link></li>
                <li class="my-2"><Link class="block mt-1 font-thin underline" :href="route(`cookie.${currentLanguage}`)">{{ $t('policy.cookie_policy') }}</Link></li>
                <li class="my-2"><a class="block mt-1 font-thin underline" target="_blank" href="https://docs.clasora.com/en/reference/release-notes/">Release Notes</a></li>
            </ul>
        </div>
    </div>
</template>

<style scoped>

.card:hover > .card-remove {
    display: block !important;
}

</style>
