<script setup>
import { onBeforeMount, onMounted, ref } from 'vue';
import { Head, Link, usePage } from '@inertiajs/vue3';
import { loadLanguageAsync, getActiveLanguage } from 'laravel-vue-i18n';
import SerbianLinks from '@/Components/FooterLinks/SerbianLinks.vue';
import EnglishLinks from '@/Components/FooterLinks/EnglishLinks.vue';

import LogoWhiteSVG from '../../images/logo-white.svg?component';
import CrowdinPNG from '../../images/clasora-crowdin.png';
import FacebookSVG from '../../images/clasora-facebook.svg?component';
import InstagramSVG from '../../images/clasora-instagram.svg?component';
import LinkedInSVG from '../../images/clasora-linkedin.svg?component';
import TiktokSVG from '../../images/clasora-tiktok.svg?component';

const props = defineProps({
  // Define prop `popularTags` which expects an Array
  popularTags: Array,
  setSelectedTag: Function
});

const page = usePage();

const currentLanguage = ref(getActiveLanguage());
const currentPage = ref(page.url);

const currentYear = ref(new Date().getFullYear());

const user = ref(page.props.auth.user);
const canLogin = ref(page.props.canLogin);
const canRegister = ref(page.props.canRegister);
const showingNavigationDropdown = ref(false);

const languages = ref(['English', 'French', 'Serbian', 'Spanish']);
</script>

<template>
    <div>
        <footer class="bg-primary text-white px-12 md:px-24 pt-20 pb-8 mb-[77px] md:mb-0 " v-if="![$t('routes.messages')].includes(decodeURIComponent(currentPage.split('?')[0].split('/')[2]))">
            <SerbianLinks v-if="currentLanguage == 'sr'" />
            <EnglishLinks v-else />
            <div class="mt-12 relative">
                <div class="flex justify-start md:justify-end mb-10 md:mb-0 relative md:absolute bottom-0 right-0 h-[32px]">
                    <a href="https://crowdin.com/project/clasora/invite?h=aa017268f48954e9e8a1490dd44b9ac92209881" target="_blank"><img :src="CrowdinPNG" class="w-[33px] h-[33px] mr-[16px]"/></a>
                    <a href="https://www.facebook.com/profile.php?id=61562287170126" target="_blank"><FacebookSVG class="w-12 h-12"/></a>
                    <a href="https://www.instagram.com/clasora_platform/" target="_blank"><InstagramSVG class="w-12 h-12"/></a>
                    <a href="https://www.linkedin.com/company/classroom-com/" target="_blank"><LinkedInSVG class="w-12 h-12"/></a>
                    <a href="https://www.tiktok.com/@clasora.com" target="_blank"><TiktokSVG class="w-12 h-12"/></a>
                </div>
                <p class="font-thin text-center">&copy; {{ currentYear }} clasora.com platform | {{ $t('All Rights Reserved') }}</p>
            </div>
        </footer>
    </div>
</template>
