<script setup>
import { onBeforeMount, onMounted, ref } from 'vue';
import ApplicationLogo from '@/Components/ApplicationLogo.vue';
import Dropdown from '@/Components/Dropdown.vue';
import DropdownLink from '@/Components/DropdownLink.vue';
import NavLink from '@/Components/NavLink.vue';
import ResponsiveNavLink from '@/Components/ResponsiveNavLink.vue';
import { Head, Link, usePage } from '@inertiajs/vue3';
import { loadLanguageAsync, getActiveLanguage } from 'laravel-vue-i18n';
import LanguageSelect from '@/Components/LanguageSelect.vue';
import ChatIndicator from '@/Components/ChatIndicator.vue';
import HeaderLayout from '@/Layouts/HeaderLayout.vue';
import FooterLayout from '@/Layouts/FooterLayout.vue';
import LogoSVG from '../../images/logo.svg?component';
import LogoWhiteSVG from '../../images/logo-white.svg?component';
import LogoSmallSVG from '../../images/logo-small.svg?component';
import BottomNavBar from '@/Components/BottomNavBar.vue';
import ConsentModal from '@/Pages/Policies/ConsentModal.vue';
import submitBackendTracking from '@/tracking.js';

const props = defineProps({
  // Define prop `popularTags` which expects an Array
  popularTags: Array,
  setSelectedTag: Function
});

const page = usePage();

const user = ref(page.props.auth.user);

const app = ref(page.props.app);

if (user.value) {
    if (typeof window !== 'undefined') {
        if (window._paq) {
            window._paq.push(['setUserId', user.value.email]);
        }
    }
}

submitBackendTracking(page.props.tracking, page.props.auth.user);

</script>

<template>
    <div>
        <div 
            class="min-h-screen bg-gray-100"
            :class="{
                'pt-8': app === 'ios'
            }"
        >
            <HeaderLayout />
            <main v-if="user" class="relative">
            <slot />
            </main>
            <main v-else class="relative">
            <slot />
            </main>
            <BottomNavBar />
            <FooterLayout :popularTags="popularTags" :setSelectedTag="setSelectedTag" />
            <ConsentModal />
        </div>
    </div>
</template>
