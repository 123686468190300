<script setup>
import { onMounted, onUnmounted, ref } from 'vue';
import { Link } from '@inertiajs/vue3';
import { getActiveLanguage } from 'laravel-vue-i18n';
import MessageSVG from '../../images/message.svg?component';
import MessageSmallSVG from '../../images/message-small.svg?component';

let intervalId = null;
const unreadMessages = ref(0);

const currentLanguage = ref(getActiveLanguage());

function checkUnreadMessages() {
    if (window.fetch) {
        window.fetch(route(`messages.unread.count.${currentLanguage.value}`))
            .then(response => response.json())
            .then(data => {
                unreadMessages.value = data.total;
            })
            .catch(error => {
                console.error('Error loading more posts:', error);
            });
    } else {
        console.error('Fetch API not supported');
    }
}

onMounted(() => {
    if (typeof window !== 'undefined') {
        if (intervalId) {
            clearInterval(intervalId);
        }
        intervalId = setInterval(checkUnreadMessages, 60000);
        setTimeout(checkUnreadMessages, 2000);
    }
});

onUnmounted(() => {
    if (intervalId) {
        clearInterval(intervalId);
    }
});

</script>

<template>
    <a class="relative" :href="route(`messages.${currentLanguage}`)">
        <div class="md:hidden flex items-center justify-center border border-gray-300 font-medium font-semibold rounded-md text-gray-900 bg-white md:h-10 h-8 md:w-10 w-8 cursor-pointer pointer-events-none"><MessageSmallSVG /></div>
        <div class="md:flex hidden items-center justify-center border border-gray-300 font-medium font-semibold rounded-md text-gray-900 bg-white md:h-10 h-8 md:w-10 w-8 cursor-pointer pointer-events-none"><MessageSVG /></div>
        <template v-if="unreadMessages > 0">
            <div class="absolute top-0 right-0 -mt-1 -mr-1 bg-primary text-white rounded-full text-xs font-semibold px-1 z-10 w-5 h-5 text-center flex items-center justify-center pointer-events-none">{{ unreadMessages }}</div>
        </template>
    </a>
</template>

<style scoped>
/** for ChatIndicator.blade.php */
.nonexistant-class {
  background-image: url('../../images/message-small.svg');
  background-image: url('../../images/message.svg');
}
</style>