<script setup>
import { ref } from 'vue';
import { Head, Link, usePage } from '@inertiajs/vue3';
import LanguageList from '@/Components/Footer/LanguageList.vue';

import { loadLanguageAsync, getActiveLanguage, trans } from 'laravel-vue-i18n';
const currentLanguage = ref(getActiveLanguage());

const page = usePage();
const user = ref(page.props.auth.user);
const locales = ref(page.props.available_locales);

</script>

<template>
    <div class="grid grid-cols-1 md:grid-cols-3 m-auto gap-6 md:gap-3">
        <div>
            <h5 class="text-base font-semibold mb-4 uppercase">{{ $t('Private language lessons') }}</h5>
            <ul class="mt-3">
                <li class="my-2"><a href="/sr/predavanje/engleski?speaking=sr">{{ $t('Private English language lessons') }}</a></li>
                <li class="my-2"><a href="/sr/predavanje/francuski?speaking=sr">{{ $t('Private French language lessons') }}</a></li>
                <li class="my-2"><a href="/sr/predavanje/srpski/za-strance?speaking=sr">{{ $t('Serbian language lessons for foreigners') }}</a></li>
                <li class="my-2"><a href="/sr/predavanje/ruski?speaking=sr">{{ $t('Private Russian language lessons') }}</a></li>
            </ul>
        </div>
        <div>
            <h5 class="text-base font-semibold mb-4 uppercase">{{ $t('subjects.Mathematics') }}</h5>
            <ul class="mt-3">
                <li class="my-2"><a href="/sr/predavanje/matematika/osnovna-skola-5-8?speaking=sr">{{ $t('Private math lessons for elementary school students') }}</a></li>
                <li class="my-2"><a href="/sr/predavanje/matematika/srednja-skola?speaking=sr">{{ $t('Private math lessons for high school students') }}</a></li>
                <li class="my-2"><a href="/sr/predavanje/matematika/fakultet?speaking=sr">{{ $t('University-level math tutoring') }}</a></li>
                <li class="my-2"><a href="/sr/predavanje/matematika/priprema-za-prijemni-ispit?speaking=sr">{{ $t('Entrance exam preparation in mathematics') }}</a></li>
            </ul>
        </div>
        <div>
            <h5 class="text-base font-semibold mb-4 uppercase">{{ $t('Natural Sciences') }}</h5>
            <ul class="mt-3">
                <li class="my-2"><a href="/sr/predavanje/hemija?speaking=sr">{{ $t('Private chemistry lessons') }}</a></li>
                <li class="my-2"><a href="/sr/predavanje/fizika?speaking=sr">{{ $t('Private physics lessons') }}</a></li>
                <li class="my-2"><a href="/sr/predavanje/fizika/priprema-za-malu-maturu?speaking=sr">{{ $t('Private Tutoring for Small Matura Physics') }}</a></li>
            </ul>
        </div>        
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 mx-auto mt-4 md:mt-12 gap-6 md:gap-3">
        <div>
            <h5 class="text-base font-semibold mb-4 uppercase">{{ $t('Exam and Test Preparation') }}</h5>
            <ul class="mt-3">
                <li class="my-2"><a href="/sr/predavanje/engleski/toefl?speaking=sr">{{ $t('TOEFL preparation') }}</a></li>
                <li class="my-2"><a href="/sr/predavanje/engleski/ielts?speaking=sr">{{ $t('IELTS preparation') }}</a></li>
                <li class="my-2"><a href="/sr/predavanje/francuski/delf?speaking=sr">{{ $t('DELF preparation') }}</a></li>
            </ul>
        </div>
        <div>
            <h5 class="text-base font-semibold mb-4 uppercase">{{ $t('Musical Instruments') }}</h5>
            <ul class="mt-3">
                <li class="my-2"><a href="/sr/predavanje/klavir?speaking=sr">{{ $t('Private piano lessons') }}</a></li>
                <li class="my-2"><a href="/sr/predavanje/muzicka-teorija?speaking=sr">{{ $t('Online music lessons') }}</a></li>
            </ul>
        </div>
        <div>
            <h5 class="text-base font-semibold mb-4 uppercase">{{ $t('Programming and IT') }}</h5>
            <ul class="mt-3">
                <li class="my-2"><a href="/sr/predavanje/java/fakultet?speaking=sr">{{ $t('Java Programming for University Students') }}</a></li>
                <li class="my-2"><a href="/sr/predavanje/matlab?speaking=sr">{{ $t('Matlab lessons') }}</a></li>
            </ul>
        </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 m-auto mt-4 md:mt-12 gap-6 md:gap-3">
        <LanguageList />
        <div>
            <h5 class="text-base font-semibold mb-4 uppercase">{{ $t('Support') }}</h5>
            <ul class="mt-3">
                <li class="my-2"><a href="mailto:office@clasora.com" class="hover:underline">office@clasora.com</a></li>
                <li class="my-2">
                    <a v-if="user" href="https://feedback.clasora.com/o_auths/1/start?reason=login" target="_blank" class="hover:underline">{{ $t('Leave Feedback') }}</a>
                    <a v-if="!user" href="https://feedback.clasora.com" target="_blank" class="hover:underline">{{ $t('Leave Feedback') }}</a>
                </li>
                <li class="my-2"><a href="https://discord.gg/bGjERVngq9" target="_blank" class="hover:underline">{{ $t('Discord Server') }}</a></li>
            </ul>
        </div>
        <div>
            <h5 class="text-base font-semibold mb-4 uppercase">{{ $t('Useful Links') }}</h5>
            <ul class="mt-3">
                <li class="my-2"><Link class="block mt-1 font-thin underline" :href="route(`faq.${currentLanguage}`)">{{ $t('policy.faq' ) }}</Link></li>
                <li class="my-2"><Link class="block mt-1 font-thin underline" :href="route(`privacy.${currentLanguage}`)">{{ $t('policy.privacy_policy') }}</Link></li>
                <li class="my-2"><Link class="block mt-1 font-thin underline" :href="route(`cookie.${currentLanguage}`)">{{ $t('policy.cookie_policy') }}</Link></li>
                <li class="my-2"><a class="block mt-1 font-thin underline" target="_blank" href="https://docs.clasora.com/sr/reference/release-notes/">Beleške za ažuriranje</a></li>
            </ul>
        </div>
    </div>
</template>

<style scoped>

.card:hover > .card-remove {
    display: block !important;
}

</style>
