<script setup>
import { ref } from 'vue';
import { Head, Link, usePage } from '@inertiajs/vue3';

import { loadLanguageAsync, getActiveLanguage, trans } from 'laravel-vue-i18n';
const currentLanguage = ref(getActiveLanguage());

const page = usePage();
const user = ref(page.props.auth.user);
const locales = ref(page.props.available_locales);
const alternate_urls = ref(page.props.alternate_urls);

</script>

<template>
    <div>
        <h5 class="text-base font-semibold mb-4 uppercase">{{ $t('Languages') }}</h5>
        <ul v-if="alternate_urls" class="mt-3">
            <li v-for="[code, lang] in Object.entries(locales)" class="my-2"><Link class="block mt-1 font-thin" :href="alternate_urls[code]" @click.prevent="loadLanguageAsync(code)">{{ lang.native }}</Link></li>
        </ul>
        <ul v-else class="mt-3">
            <li v-for="[code, lang] in Object.entries(locales)" class="my-2"><Link class="block mt-1 font-thin" :href="route([...route().current().split('.').slice(0, -1), code].join('.'), route().params)" @click.prevent="loadLanguageAsync(code)">{{ lang.native }}</Link></li>
        </ul>
    </div>
</template>
