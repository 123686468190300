<script setup>
import { loadLanguageAsync, getActiveLanguage, trans } from 'laravel-vue-i18n';
import { Head, Link, usePage } from '@inertiajs/vue3';
import { onMounted, ref } from 'vue';
import Dropdown from '@/Components/Dropdown.vue';

const page = usePage();
const params = new URLSearchParams(page.url.split('?')[1]);
const alternate_urls = ref(page.props.alternate_urls);

const locales = ref(page.props.available_locales);

const currentLanguage = ref(getActiveLanguage());

/*const translateParams = (params, lang) => {
  params.forEach((value, key) => {
    if (trans(value)) {
      // params[key] = trans(value);
      params.set(key, trans(value));
    }
  });
  return params.toString();
}*/

const translateParams = (params, lang) => {
  const newParams = new URLSearchParams(params);
  newParams.forEach((value, key) => {
    if (trans(value)) {
      newParams.set(key, trans(value));
    }
  });
  return newParams;
};


</script>

<template>
  <Dropdown class="ltr:origin-top-right rtl:origin-top-left end-0 w-16">
    <template #trigger>
      <button
        class="md:h-10 h-8 inline-flex items-center px-4 py-2 border border-gray-300 font-medium font-semibold rounded-md text-gray-900 bg-white hover:text-gray-700 focus:outline-none transition ease-in-out duration-150">
        {{ currentLanguage.toUpperCase() }}
        <svg class="ms-2 -me-0.5 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
          fill="currentColor">
          <path fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd" />
        </svg>
      </button>
    </template>
    <template #content>
      <div v-if="alternate_urls" class="flex flex-col text-center">
        <Link v-for="[code, lang] in Object.entries(locales)" :href="alternate_urls[code]" @click.prevent="loadLanguageAsync(code)">{{ code.toUpperCase() }}</Link>
      </div>
      <div v-else class="flex flex-col text-center">
        <Link v-for="[code, lang] in Object.entries(locales)" :href="route([...route().current().split('.').slice(0, -1), code].join('.'), route().params)" @click.prevent="loadLanguageAsync(code)">{{ code.toUpperCase() }}</Link>
      </div>
    </template>
  </Dropdown>
</template>
