<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue';

const open = ref(false);

const props = defineProps({
    class: {
        type: String,
        default: '',
    }
    // align: {
    //     type: String,
    //     default: 'right',
    // },
    // width: {
    //     type: String,
    //     default: '48',
    // },
    // contentClasses: {
    //     type: String,
    //     default: '',
    // },
});

const closeOnEscape = (e) => {
    if (open.value && e.key === 'Escape') {
        open.value = false;
    }
};

onMounted(() => document.addEventListener('keydown', closeOnEscape));
onUnmounted(() => document.removeEventListener('keydown', closeOnEscape));

// const widthClass = computed(() => {
//     return {
//         48: 'w-48',
//         16: 'w-16',
//     }[props.width.toString()];
// });

// const alignmentClasses = computed(() => {
//     if (props.align === 'left') {
//         return 'ltr:origin-top-left rtl:origin-top-right start-0';
//     } else if (props.align === 'right') {
//         return 'ltr:origin-top-right rtl:origin-top-left end-0';
//     } else {
//         return 'origin-top';
//     }
// });

</script>

<template>
    <div class="relative">
        <div @click="open = !open">
            <slot name="trigger" />
        </div>

        <div v-show="open" class="fixed inset-0 z-40" @click="open = false"></div>
            <div
                v-show="open"
                class="absolute z-50 mt-2 rounded-md shadow-lg"
                :class="props.class"
                style="display: none"
                @click="open = false"
            >
                <div class="rounded-md ring-1 ring-black ring-opacity-5 py-1 bg-white">
                    <slot name="content" />
                </div>
            </div>
    </div>
</template>
