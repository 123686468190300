<script setup>
import { onBeforeMount, onMounted, ref } from 'vue';
import ApplicationLogo from '@/Components/ApplicationLogo.vue';
import Dropdown from '@/Components/Dropdown.vue';
import DropdownLink from '@/Components/DropdownLink.vue';
import NavLink from '@/Components/NavLink.vue';
import ResponsiveNavLink from '@/Components/ResponsiveNavLink.vue';
import { Head, Link, usePage } from '@inertiajs/vue3';
import { loadLanguageAsync, getActiveLanguage } from 'laravel-vue-i18n';
import LanguageSelect from '@/Components/LanguageSelect.vue';
import ChatIndicator from '@/Components/ChatIndicator.vue';
import LogoSVG from '../../images/logo.svg?component';
import LogoWhiteSVG from '../../images/logo-white.svg?component';
import LogoSmallSVG from '../../images/logo-small.svg?component';

const page = usePage();

const currentLanguage = ref(getActiveLanguage());
const currentPage = ref(page.url);

const currentYear = ref(new Date().getFullYear());

const user = ref(page.props.auth.user);
const canLogin = ref(page.props.canLogin);
const canRegister = ref(page.props.canRegister);

</script>

<template>
  <div class="hidden md:block">
    <nav class="shadow-header bg-white border-b border-gray-100" v-if="user">
      <div class="mx-auto px-4 w-100">
          <div class="flex justify-between h-16 md:h-20">
              <div class="flex">
                  <div class="space-x-8 sm:-my-px sm:ms-10 sm:flex self-center">
                    <div class="flex items-center gap-3">
                      <Link :href="route(`posts.${currentLanguage}`)" class="flex h-full items-center text-primary font-bold text-xl mr-2">
                          <span alt="LOGO" class="hidden sm:flex w-40 h-16 md:h-20 flex-col justify-center"><LogoSVG /></span>
                          <span alt="LOGO" class="flex sm:hidden w-10 h-10 flex-col justify-center"><LogoSmallSVG /></span>
                      </Link>
                      <Link :href="route(`posts.${currentLanguage}`)" class="hidden md:block text-gray-900 font-semibold border border-primary rounded-md px-4 py-2">
                          {{ $t('Posts') }}
                      </Link>
                    </div>
                  </div>
              </div>

              <div class="flex items-center sm:ms-6">
                  <div class="ms-3 relative flex gap-2">
                      <LanguageSelect class="self-center"/>
                      <ChatIndicator class="self-center" />
                      <Dropdown class="ltr:origin-top-right rtl:origin-top-left end-0 self-center w-48">
                          <template #trigger>
                              <span class="inline-flex rounded-md">
                                  <button type="button"
                                      class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 bg-white hover:text-gray-700 focus:outline-none transition ease-in-out duration-150">
                                      {{ user.first_name }}
                                      <svg class="ms-2 -me-0.5 h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 20 20" fill="currentColor">
                                          <path fill-rule="evenodd"
                                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                              clip-rule="evenodd" />
                                      </svg>
                                  </button>
                              </span>
                          </template>

                          <template #content>
                                <DropdownLink :href="route(`dashboard.${currentLanguage}`)">
                                  {{ $t('Dashboard') }}
                                </DropdownLink>
                                <DropdownLink :href="route(`posts.${currentLanguage}`)">
                                    {{ $t('Posts') }}
                                </DropdownLink>
                                <DropdownLink :href="route(`profile.edit.${currentLanguage}`)">
                                    {{ $t('Profile') }}
                                </DropdownLink>
                                <DropdownLink :href="route(`post.create.step1.${currentLanguage}`)" v-if="user.roles.includes('teacher')">
                                    {{ $t('Create New Post') }}
                                </DropdownLink>
                                <DropdownLink :href="route(`lesson.create.${currentLanguage}`)" v-if="user.roles.includes('teacher')">
                                  {{ $t('Report new lesson') }}
                                </DropdownLink>
                                <a 
                                    class="block w-full px-4 py-2 text-start text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out" 
                                    href="https://discord.gg/bGjERVngq9" target="_blank">
                                        {{ $t('Discord') }}
                                </a>
                                <a      
                                    class="block w-full px-4 py-2 text-start text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out" 
                                    href="https://feedback.clasora.com/o_auths/1/start?reason=login" 
                                    target="_blank">
                                    {{ $t('Feedback') }}
                                </a>
                                <DropdownLink :href="route(`logout.${currentLanguage}`)"
                                    as="button">
                                    {{ $t('Log Out') }}
                                </DropdownLink>
                          </template>
                      </Dropdown>
                  </div>
              </div>
          </div>
      </div>
    </nav>
    <nav class="shadow-header bg-white border-b border-gray-100" v-else>
        <div class="flex gap-3 h-16 md:h-20 items-center justify-between px-4 md:px-10 lg:px-20">
            <div class="flex items-center gap-3">
                <Link :href="route(`posts.${currentLanguage}`)" class="flex h-full text-primary font-bold text-xl mr-2">
                    <span alt="LOGO" class="hidden sm:flex w-40 h-16 md:h-20 flex-col justify-center"><LogoSVG /></span>
                    <span alt="LOGO" class="flex sm:hidden w-10 h-10 flex-col justify-center"><LogoSmallSVG /></span>
                </Link>
                <Link :href="route(`register.${currentLanguage}`)" class="hidden md:block text-gray-900 font-semibold border border-primary rounded-md px-4 py-2">
                    {{ $t('Become a teacher') }}
                </Link>
            </div>
            <div class="flex items-center gap-2">
                <LanguageSelect />
                <Link v-if="canLogin" :href="route(`login.${currentLanguage}`)"
                    class="inline-flex items-center justify-center px-4 py-2 md:h-10 h-8 text-white bg-primary border border-primary rounded-md hover:bg-primary-dark">
                    {{ $t('auth.action') }}
                </Link>
            </div>
        </div>
    </nav>
  </div>
</template>

<style scoped>
/** for ChatIndicator.blade.php */
.nonexistant-class {
  background-image: url('../../images/logo.svg');
  background-image: url('../../images/logo-small.svg');
}
</style>