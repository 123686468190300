const URLEncoder = {
    encode: (string) => {
        string = URLEncoder.encodeUnsupportedChars(string);
        string = URLEncoder.ruCyrillicToLatin(string);
        string = URLEncoder.srCyrillictoLatin(string);
        string = URLEncoder.ukCyrillicToLatin(string);
        string = URLEncoder.esToLatin(string);
        string = URLEncoder.deToLatin(string);
        string = URLEncoder.plToLatin(string);
        string = URLEncoder.frToLatin(string);
        return string;
    },
    ruCyrillicToLatin: (string) => {
        const ruCyrillic = ['а', 'б', 'в', 'г', 'д', 'е', 'ё', 'ж', 'з', 'и', 'й', 'к', 'л', 'м', 'н', 'о', 'п', 'р', 'с', 'т', 'у', 'ф', 'х', 'ц', 'ч', 'ш', 'щ', 'ъ', 'ы', 'ь', 'э', 'ю', 'я'];
        const latin = ['a', 'b', 'v', 'g', 'd', 'e', 'e', 'zh', 'z', 'i', 'y', 'k', 'l', 'm', 'n', 'o', 'p', 'r', 's', 't', 'u', 'f', 'kh', 'ts', 'ch', 'sh', 'shch', 'ie', 'y', '', 'e', 'yu', 'ya'];
        return string.split('').map(char => {
            const index = ruCyrillic.indexOf(char);
            return index !== -1 ? latin[index] : char;
        }).join('');
    },
    ukCyrillicToLatin: (string) => {
        const ukCyrillic = ['а', 'б', 'в', 'г', 'ґ', 'д', 'е', 'є', 'ж', 'з', 'и', 'і', 'ї', 'й', 'к', 'л', 'м', 'н', 'о', 'п', 'р', 'с', 'т', 'у', 'ф', 'х', 'ц', 'ч', 'ш', 'щ', 'ь', 'ю', 'я'];
        const latin = ['a', 'b', 'v', 'h', 'g', 'd', 'e', 'ye', 'zh', 'z', 'y', 'i', 'yi', 'y', 'k', 'l', 'm', 'n', 'o', 'p', 'r', 's', 't', 'u', 'f', 'kh', 'ts', 'ch', 'sh', 'shch', '', 'yu', 'ya'];
        return string.split('').map(char => {
            const index = ukCyrillic.indexOf(char);
            return index !== -1 ? latin[index] : char;
        }).join('');
    },
    esToLatin: (string) => {
        const esCyrillic = ['á', 'é', 'í', 'ó', 'ú', 'ü', 'ñ'];
        const latin = ['a', 'e', 'i', 'o', 'u', 'u', 'n'];
        return string.split('').map(char => {
            const index = esCyrillic.indexOf(char);
            return index !== -1 ? latin[index] : char;
        }).join('');
    },
    deToLatin: (string) => {
        const deCyrillic = ['ä', 'ö', 'ü', 'ß'];
        const latin = ['ae', 'oe', 'ue', 'ss'];
        return string.split('').map(char => {
            const index = deCyrillic.indexOf(char);
            return index !== -1 ? latin[index] : char;
        }).join('');
    },
    srCyrillictoLatin: (string) => {
        const srCyrillic = ['č', 'ć', 'đ', 'š', 'ž'];
        const latin = ['c', 'c', 'd', 's', 'z'];
        return string.split('').map(char => {
            const index = srCyrillic.indexOf(char);
            return index !== -1 ? latin[index] : char;
        }).join('');
    },
    plToLatin: (string) => {
        const plCyrillic = ['ą', 'ć', 'ę', 'ł', 'ń', 'ó', 'ś', 'ź', 'ż'];
        const latin = ['a', 'c', 'e', 'l', 'n', 'o', 's', 'z', 'z'];
        return string.split('').map(char => {
            const index = plCyrillic.indexOf(char);
            return index !== -1 ? latin[index] : char;
        }).join('');
    },
    frToLatin: (string) => {
        const frCyrillic = ['à', 'â', 'ç', 'è', 'é', 'ê', 'ë', 'î', 'ï', 'ô', 'œ', 'ù', 'û', 'ü'];
        const latin = ['a', 'a', 'c', 'e', 'e', 'e', 'e', 'i', 'i', 'o', 'oe', 'u', 'u', 'u'];
        return string.split('').map(char => {
            const index = frCyrillic.indexOf(char);
            return index !== -1 ? latin[index] : char;
        }).join('');
    },
    encodeUnsupportedChars: (string) => {
        string = string.replace(/\s/g, '-').toLowerCase();
        string = string.replace(/#/g, '-hash-').replace(/\//g, '-slash-');
        string = string.replace(/\'/g, '');
        return string.replace(/-+$/, '');
    }
}

export {
    URLEncoder,
};